:root{
    --primary-color: #005068;
}

*{
    margin: 0;
    padding: 0;
}
body{
    font-family: 'Inter', sans-serif!important;
    font-weight: 500;
    color: #333;
    background: #F5F7FA;
}
h1,h2,h3,h4,h5,h6{
    font-weight: bold!important;
}
strong{
    font-weight: 600;
}
label{
    color: #696868;
}
.navbar{
    background: var(--primary-color);
}
.brand-logo{
    padding-left: 15px!important;
    font-size: 1.5rem!important;
    font-weight: bold;
}
.login_card_content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.login_card{
    margin: auto;
}
.login_card .card{
    max-width: 500px;
    margin: auto;
}
.containerx{
    max-width: 1300px;
    margin: auto;
    overflow-x: auto;
    padding: 10px;
}
/* home */

.table tr{
    border-bottom: 10px solid #f2f6f7;
}
.table td{
    background: #FFF;
}
.table a{
    display: block;
}
.table .material-icons{
    margin-right: 8px;
    border-radius: 50%;
    padding: 2px;
    color: #FFF!important;
}

.table .done{
    background-color: var(--primary-color);
}
.table .access_alarm{
    background-color: orange;
}
.table tr td:first-child>div{
    display: flex;
    align-items: center;
}
a.contact_link{
    font-weight: bold;
    color: var(--primary-color);
}
/* Details */

.contact_content{
    background: #FFF;
    padding: 10px;
    margin-top: 30px;
}
.contact_content input, .contact_content textarea{
    border: 1px solid #e5e7eb!important;
    box-sizing: border-box!important;
    border-radius: 4px!important;
    padding: 23px 15px!important;
    background: #FFF!important;
    color: #333;
    font-weight:500;
}
.contact_content input:focus{
    border-color: rgba(59, 130, 246, 0.5)!important;
}
.contact_content .col{
    padding-top: 20px!important;
}
.contact_content span{
    display: block;
    padding: 5px 0px;
}
.input-field label{
    line-height: 2px;
}
.input-field label.active{
    /**top: 15px; **/
}
.contact_content .name .avatar{
    width: 60px;
    background: tomato;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
    color: #FFF;
    font-size: 30px;
}
.contact_content .name{
    display: flex;
    align-items: center;
}
.name h4{
    margin: 0;
}
.photos_container{
    display: flex;
}
.contact_content hr{
    border-color: cornflowerblue;
    opacity: 0.2;
}
.contact_photo{
    max-width: 130px;
    padding-right: 8px;
}
.contact_photo img{
    width: 100%;
}
/*  */

.mr-2{
    margin-right: 10px;
}
.attended{
    padding-top: 10px;
}
.input_file{
    width: 100%;
}
.back svg{
    width: 30px;
    margin-right: 10px;
}
.back{
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    color: #333;
}

.btn.grey{
    color: #333!important;
    margin-right: 10px!important;
}

.align-right{
    text-align: right;
}

.action_buttons{
    max-width: 150px;
    width: 150px;
}

.sure{
    font-size: 20px;
}

.td_buttons{
    display: flex;
}


@media(max-width:432px){
    .mr-2{
        margin-bottom: 5px!important;
    }
    .avatar{
        width: 70px!important;
    }
}

@media(min-width:600px ) and (max-width:1187px){
    .mr-2{
        margin-bottom: 5px!important;
    }
}
@media(max-width:678px){
    .show-on-large{
        display: none;
    }
    .form-content{
        width: 100%;
    }
    .form-content>div{
        width: 100%!important;
    }
}

.container.login{
    padding: 20px;
    min-height: 60vh;
    background: #FFF;
    border-radius: 10px;
}
.outside_content{
    height: 100vh;
    display: flex;
    align-items: center;
}
.side_content{
    /* background-image: linear-gradient(to top, #f43b47 0%, #453a94 100%); */
    background-image: linear-gradient(15deg, #9795f0 0%, #fbc8d4 100%);
    border-radius: 10px;
    min-height: 60vh;
    display: flex;
    padding: 20px;
    align-items: center;
    position: relative;
    background: url('https://unives.mx/wp-content/uploads/2021/03/sidebar2.jpg');
    background-position: center;
    background-size: cover;
}
.side_content p, .side_content span, .side_content h5{
    text-shadow: 1px 1px 15px #fff;
}
.container.login .row{
    margin-bottom: 0;
}
.card-content img{
    height: 100px;
    width: 100px;
}
.side_content span{
    position: absolute;
    bottom: 30px;
    width: 100%;
}
.form-content>div{
    min-height: 60vh;
    display: flex;
    align-items: center;
}

.outside_content .card-content{
    /* width: 100%!important; */
    /* min-width: 500px; */
    margin: auto;
}
.card-panel.white .row{
    display: flex;
    align-items: center;
}

@media(max-width:800px){
    .outside_content .card-content{
        width: 100%;
    }
}
@media(min-width:801px){
    .outside_content .card-content{
        width: 75%;
    }
}


/* Profile */

.profile_content {
    display: flex;
    justify-content: center;
}
.card, .card-panel{
    box-shadow: 0 4px 23px rgb(0 0 0 / 5%);
}
.avatar_content{
    display: flex;
    justify-content: center;
}
.card-title{
    margin-bottom: 15px;
}
.card-content{
    min-width: 100px;
    overflow-x: auto;
}
.mr-2{
    margin-right: 10px!important;
}
.card .card-title{
    font-size: 19px;
}
.mylink{
    background: #f8f8f8;
    padding: 10px!important;
    border: 1px solid lightblue;
    margin-bottom: 5px;
}
.mylink a{
    color: #555!important;
    text-transform: lowercase!important;
    font-weight: 600;
}
input, .materialize-textarea{
    border: 1px solid #e5e7eb!important;
    box-sizing: border-box!important;
    border-radius: 4px!important;
    padding: 23px 15px!important;
    background: #fff!important;
    color: #333;
    font-weight: 500;
}
.link_outside{
    color: #000;
}

/* course */

.card-image.course{
    cursor: pointer;
}
.card-image.course .label{
    position: absolute;
    top: 50%;
    color: #FFF;
    right: 32%;
    opacity: 0;
    font-weight: 500;
}
.card-image.course:hover .label{
    opacity: 1;
    transition: all 0.3s ease;
}
.card-image.course:hover img{
    filter: brightness(0.7);
    transition: all 0.3s ease;
}
tr{
    background: #FFF;
    border-bottom: 7px solid #f7f7f7;
}
tr a{
    display: block;
}
table .actions{
    width: 120px;
}
.nav-wrapper li>a{
    display: flex;
}
.nav-wrapper li i{
    margin-right: 8px;
}
.avatar_content span{
    cursor: pointer;
    font-weight: 600;
}
.depth{
    box-shadow: 0 4px 23px rgb(0 0 0 / 5%);
}
.avatar_div img{
    border-radius: 50%;
}

button.copy{
    display: flex;
    align-items: center;
}
button.copy i{
    margin-right: 10px;
}
.link_content{
    display: flex;
    width: 100%;
}
.link_content button{
    border-radius: 0!important;
    padding: 23px 10px;
}
.link_content input{
    border-radius: 0!important;
}
.notfound_content{
    width: 100%;
    text-align: center;
}

/* layout inside */

@media(min-width:992px){
    main{
        margin-left: 250px;
    }
}
@media(max-width:768px){
    .sidenav{
        margin-top: 55px!important;
    }
}
.sidenav{
    z-index: 999;
    margin-top: 60px;
    width: 250px!important;
    box-shadow: 0 4px 23px rgb(0 0 0 / 5%);
}
nav{
    z-index: 9999;
    position: fixed;
    top: 0;
}
main{
    margin-top: 75px;
}
.nav-wrapper{
    /** margin-left: 30px; **/
}
.sidenav li{
    display: flex;
    align-items: center;
    /**padding-left: 10px;**/
    cursor: pointer;
}
.sidenav li>a{
    width: 100%;
    /* padding-left: 0; */
}
.sidenav li i{
    margin-right: 10px!important;
}
.divider{
    width: 95%;
}
select{
    display: block;
}
.profile_content label{
    line-height: 3px;
}
.market_grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}
.card{
    height: 100%;
}
@media (max-width:768px) and (min-width:500px){
    .market_grid{
        grid-template-columns: 1fr 1fr;
    }
}
@media(max-width:500px){
    .market_grid{
        grid-template-columns: 1fr;
    }
}
.collection-item{
    cursor: pointer;
}
.collection-item:hover{
    background: #f9f9f9;
}
.card-title{
    display: flex;
    align-items: center;
}
.lesson_actions .delete{
    color: #d04237;
    margin-right: 10px;
}
.lesson_actions .edit{
    color: rgb(29, 93, 212);
}
.lesson_actions .material-icons{
    border-radius: 50%;
    border: 1px solid lightgray;
    padding: 5px;
}
.lesson_actions .material-icons:hover{
    background: #FFF;
}

/** lesson items */

@media (max-width: 768px) {
    .lesson-list .collection-item{
        height: 130px;
    }
    .lesson_actions{
        position: relative!important;
        display: contents;
        width: 100%;
        top: 10px;
        left: 0;
    }
}

.act-box{
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 4px;
    text-align: center
}
span.status{
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
}
span.status.success{
    background: #005068;
}
span.status.danger{
    background: orange;
}
.back-section{
    display: inline-flex;
    align-items: center;
}
.back-section svg{
    width: 20px;
    color: #000;
    margin-right: 10px;
}
.back-section .card-title{
    margin-bottom: 15px;
}
.sidenav a.active {
    background: #edf5ff;
}
.remove_btn_contact>i.material-icons{
    padding: 2px 4px;
}